import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import Notification from "../../components/Notifications/Notification";
import { HashRouter as Router } from 'react-router-dom';


const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    fetch("https://banke2etreinamentos.e2etreinamentos.com.br/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Usuário ou senha incorretos");
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("authToken", data.token);
        localStorage.setItem("nomeCliente", data.nomeCliente);
        
        window.location.href = "/cliente-logado";
    })
    
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const handleRegisterRedirect = () => {
    navigate("/");
  };

  const handleCloseNotification = () => {
    setErrorMessage("");
  };

  return (
    <div className="form-overlay">
      <Notification
        message={errorMessage}
        type="error"
        onClose={handleCloseNotification}
      />
      <div className="form-background">
        <img
          src={`${process.env.PUBLIC_URL}/imgs/fundo-login.svg`}
          alt="Tela de Login"
          className="form-background-image"
        />
      </div>

      <div className="form-container">
        <img
          src={`${process.env.PUBLIC_URL}/imgs/logo-e2e.svg`}
          alt="Logo E2E"
          className="form-logo"
        />
        <form className="login-form">
          <h2>Login</h2>
          <div className="input-container-username">
            <input
              type="text"
              name="username"
              placeholder="CPF ou E-mail"
              value={username}
              onChange={handleUsernameChange}
              required
            />
            <span className="required-icon">*</span>
          </div>
          <div className="input-container">
            <input
              type="password"
              name="password"
              placeholder="Senha"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <span className="required-icon">*</span>
          </div>
          <button type="button" className="form-button" onClick={handleLogin}>
            Continuar
          </button>
          <button
            type="button"
            className="form-button secondary-button"
            onClick={handleRegisterRedirect}
          >
            Cancelar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
