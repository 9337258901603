import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import "./Form.css"; // Import the CSS file here
import { createClient } from "../../services/clientService";
import { createAccount } from "../../services/accountService";
import { createPassword } from "../../services/passwordService";
import { generateCode } from "../../services/codeService";
import Notification from "../Notifications/Notification";
import { NumericFormat } from "react-number-format";

const Form = ({ cpf, onClose }) => {
  const [step, setStep] = useState(1);
  const [validationCode, setValidationCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordValid, setPasswordValid] = useState({
    length: false,
    number: false,
    lowercase: false,
    uppercase: false,
    specialChar: false,
    noSequentialOrIdentical: false,
    match: false,
  });

  const [personalInformation, setPersonalInformation] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    cpf: cpf,
    rg: "",
    cep: "",
    number: "",
    state: "",
    city: "",
    complement: "",
    dataBirthday: "",
  });

  const [professionalInformation, setProfessionalInformation] = useState({
    actualGroup: "",
    firstGroup: "",
    job: "",
    salary: "",
    professionalEmail: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Função para remover caracteres que não sejam letras
  const handleOnlyLetters = (value) => {
    return value.replace(/[^a-zA-ZÀ-ÿ\s]/g, "");
  };

  const handlePersonalChange = (e) => {
    const { name, value } = e.target;

    let cleanedValue = value;

    if (name === "firstName" || name === "lastName" || name === "state" || name === "city") {
      cleanedValue = handleOnlyLetters(value); // Aplicar a máscara de somente letras
    } else if (name === "rg") {
      cleanedValue = value.replace(/[^0-9xX]/g, "").toUpperCase(); // Aplicar validação para RG
    }

    setPersonalInformation({ ...personalInformation, [name]: cleanedValue });
  };

  const handleProfessionalChange = (e) => {
    const { name, value } = e.target;
    setProfessionalInformation({ ...professionalInformation, [name]: value });
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    const rawValue = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    setPersonalInformation({ ...personalInformation, phoneNumber: rawValue });
  };

  const hasSequentialOrIdenticalChars = (str) => {
    // Verifica sequências numéricas e alfabéticas
    const sequences = [
      '0123456789',
      'abcdefghijklmnopqrstuvwxyz',
      'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    ];

    for (let seq of sequences) {
      for (let i = 0; i < seq.length - 2; i++) {
        const subSeq = seq.substring(i, i + 3);
        if (str.includes(subSeq)) {
          return true;
        }
      }
    }

    // Verifica caracteres idênticos consecutivos
    return /(.)\1\1/.test(str);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setPasswordValid({
      ...passwordValid,
      length: value.length >= 8 && value.length <= 20,
      number: /\d/.test(value),
      lowercase: /[a-z]/.test(value),
      uppercase: /[A-Z]/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      noSequentialOrIdentical: !hasSequentialOrIdenticalChars(value),
      match: value === confirmPassword,
    });
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    setPasswordValid({
      ...passwordValid,
      match: value === password,
    });
  };

  const handleCodeChange = (e) => {
    setValidationCode(e.target.value);
  };

  const handleDateChange = (e) => {
    const { value } = e.target;
    setPersonalInformation({ ...personalInformation, dataBirthday: value });
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const isPersonalInformationValid = () => {
    return (
      personalInformation.firstName &&
      personalInformation.lastName &&
      validateEmail(personalInformation.email) &&
      personalInformation.phoneNumber.length === 11 && // Verifica se o número de telefone tem 11 dígitos
      personalInformation.address &&
      personalInformation.rg &&
      personalInformation.cep &&
      personalInformation.number &&
      personalInformation.state &&
      personalInformation.city &&
      personalInformation.dataBirthday
    );
  };

  const isProfessionalInformationValid = () => {
    const salaryValue = parseFloat(
      professionalInformation.salary.replace(/[R$.]/g, "").replace(",", ".")
    );
    return (
      professionalInformation.actualGroup &&
      /^[0-9]{1,6}$/.test(professionalInformation.actualGroup) &&
      professionalInformation.firstGroup &&
      /^[0-9]{1,6}$/.test(professionalInformation.firstGroup) &&
      professionalInformation.job &&
      /^[a-zA-Z\s]{2,50}$/.test(professionalInformation.job) &&
      salaryValue >= 1000 &&
      professionalInformation.professionalEmail
    );
  };

  const isPasswordValid = () => {
    return (
      passwordValid.length &&
      passwordValid.number &&
      passwordValid.lowercase &&
      passwordValid.uppercase &&
      passwordValid.specialChar &&
      passwordValid.noSequentialOrIdentical &&
      passwordValid.match
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(personalInformation.email)) {
      setErrorMessage("E-mail inválido.");
      return;
    }

    const data = {
      personalInformation,
      professionalInformation,
    };

    try {
      const clientResponse = await createClient(data);

      const accountData = {
        accountType: "Conta Corrente",
        balance: parseFloat(
          professionalInformation.salary.replace(/[R$.]/g, "").replace(",", ".")
        ),
        customerId: clientResponse.id,
      };

      const accountResponse = await createAccount(accountData);

      if (accountResponse && accountResponse.id) {
        const codeResponse = await generateCode(
          clientResponse.personalInformation.cpf
        );
        setValidationCode(codeResponse);
        setStep(3); // Avançar para a etapa de cadastro de senha
      } else {
        setErrorMessage("Erro ao criar a conta bancária. Tente novamente.");
      }
    } catch (error) {
      const detailedMessage =
        error.response?.data || "Erro ao cadastrar cliente ou criar conta";
      setErrorMessage(`Erro: ${detailedMessage}`);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (!isPasswordValid()) {
      setErrorMessage("A senha não cumpre todos os requisitos!");
      return;
    }

    try {
      await createPassword({
        email: professionalInformation.professionalEmail,
        cpf: personalInformation.cpf,
        password,
        code: validationCode,
      });
      localStorage.setItem("authToken", "seu-token-aqui"); // Armazenando o token de autenticação
      localStorage.setItem("nomeCliente", personalInformation.firstName); // Armazenando o nome do cliente
      
      navigate("/cliente-logado", { replace: true }); // Redirecionar para a página Cliente Logado substituindo o histórico
    } catch (error) {
      const detailedMessage = error.response?.data || "Erro ao cadastrar senha";
      setErrorMessage(`Erro: ${detailedMessage}`);
    }
  };

  const handleCloseNotification = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  const formatSalary = (value) => {
    const numberValue = parseFloat(
      value.replace(/[R$.]/g, "").replace(",", ".")
    );
    if (isNaN(numberValue)) {
      return "R$ 0,00";
    }
    return numberValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const handleSalaryChange = (e) => {
    setProfessionalInformation({
      ...professionalInformation,
      salary: formatSalary(e.target.value),
    });
  };

  return (
    <div className="form-overlay">
      <Notification
        message={errorMessage}
        type="error"
        onClose={handleCloseNotification}
      />
      <Notification
        message={successMessage}
        type="success"
        onClose={handleCloseNotification}
      />
      <div className="form-background">
        <img
          src={`${process.env.PUBLIC_URL}/imgs/fundo-formulario.svg`}
          alt="Fundo Formulário"
          className="form-background-image"
        />
      </div>
      <div className="form-container">
        <button className="close-button" onClick={onClose}>
          X
        </button>
        {step === 1 && (
          <form>
            <h2>Dados Pessoais</h2>
            <p></p>
            <div className={`input-container ${!personalInformation.firstName ? 'invalid' : ''}`}>
              <input
                type="text"
                name="firstName"
                placeholder="Nome"
                value={personalInformation.firstName}
                onChange={handlePersonalChange}
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!personalInformation.lastName ? 'invalid' : ''}`}>
              <input
                type="text"
                name="lastName"
                placeholder="Sobrenome"
                value={personalInformation.lastName}
                onChange={handlePersonalChange}
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!personalInformation.email ? 'invalid' : ''}`}>
              <input
                type="email"
                name="email"
                placeholder="E-mail pessoal"
                value={personalInformation.email}
                onChange={handlePersonalChange}
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!personalInformation.dataBirthday ? 'invalid' : ''}`}>
              <InputMask
                mask="99-99-9999"
                type="text"
                name="dataBirthday"
                placeholder="Data de Nascimento"
                value={personalInformation.dataBirthday}
                onChange={handleDateChange}
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!personalInformation.rg ? 'invalid' : ''}`}>
              <input
                type="text"
                name="rg"
                placeholder="RG"
                value={personalInformation.rg}
                maxLength="9"
                onChange={handlePersonalChange}
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!personalInformation.phoneNumber || personalInformation.phoneNumber.length !== 11 ? 'invalid' : ''}`}>
              <InputMask
                mask="(99) 99999-9999"
                type="text"
                name="phoneNumber"
                placeholder="Telefone"
                value={personalInformation.phoneNumber}
                onChange={handlePhoneNumberChange}
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!personalInformation.address ? 'invalid' : ''}`}>
              <input
                type="text"
                name="address"
                placeholder="Endereço"
                value={personalInformation.address}
                onChange={handlePersonalChange}
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!personalInformation.cep ? 'invalid' : ''}`}>
              <InputMask
                mask="99999-999"
                type="text"
                name="cep"
                placeholder="CEP"
                value={personalInformation.cep}
                onChange={handlePersonalChange}
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!personalInformation.number ? 'invalid' : ''}`}>
              <input
                type="text"
                name="number"
                placeholder="Número"
                value={personalInformation.number}
                onChange={handlePersonalChange}
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className="input-container">
              <input
                type="text"
                name="complement"
                placeholder="Complemento"
                value={personalInformation.complement}
                onChange={handlePersonalChange}
              />
            </div>
            <div className={`input-container ${!personalInformation.state ? 'invalid' : ''}`}>
              <input
                type="text"
                name="state"
                placeholder="Estado"
                value={personalInformation.state}
                maxLength="2"
                onChange={handlePersonalChange}
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!personalInformation.city ? 'invalid' : ''}`}>
              <input
                type="text"
                name="city"
                placeholder="Cidade"
                value={personalInformation.city}
                maxLength="100"
                onChange={handlePersonalChange}
                required
              />
              <span className="required-icon">*</span>
            </div>
            <button
              type="button"
              className="form-button"
              onClick={() => setStep(2)}
              disabled={!isPersonalInformationValid()}
            >
              Avançar
            </button>
          </form>
        )}
        {step === 2 && (
          <form onSubmit={handleSubmit}>
            <h2>Dados Profissionais</h2>
            <p></p>
            <div className={`input-container ${!professionalInformation.actualGroup ? 'invalid' : ''}`}>
              <input
                type="text"
                name="actualGroup"
                placeholder="Grupo Atual"
                value={professionalInformation.actualGroup}
                onChange={handleProfessionalChange}
                maxLength="6"
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!professionalInformation.firstGroup ? 'invalid' : ''}`}>
              <input
                type="text"
                name="firstGroup"
                placeholder="Primeiro Grupo"
                value={professionalInformation.firstGroup}
                onChange={handleProfessionalChange}
                maxLength="6"
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!professionalInformation.job ? 'invalid' : ''}`}>
              <input
                type="text"
                name="job"
                placeholder="Cargo"
                value={professionalInformation.job}
                onChange={handleProfessionalChange}
                maxLength="50"
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!professionalInformation.salary ? 'invalid' : ''}`}>
              <NumericFormat
                thousandSeparator={true}
                prefix={"R$ "}
                decimalScale={2}
                fixedDecimalScale={true}
                allowNegative={false}
                allowLeadingZeros={false}
                value={professionalInformation.salary}
                onValueChange={(values) => {
                  const { value } = values;
                  setProfessionalInformation({
                    ...professionalInformation,
                    salary: value,
                  });
                }}
                placeholder="Salário"
                required
              />
              <span className="required-icon">*</span>
            </div>
            <div className={`input-container ${!professionalInformation.professionalEmail ? 'invalid' : ''}`}>
              <input
                type="email"
                name="professionalEmail"
                placeholder="Email Profissional @e2etreinamentos.com.br"
                value={professionalInformation.professionalEmail}
                onChange={handleProfessionalChange}
                required
              />
              <span className="required-icon">*</span>
            </div>
            <button
              type="button"
              id="btnVoltar"
              className="form-button"
              onClick={() => setStep(1)}
            >
              Voltar
            </button>
            <button
              type="submit"
              id="btnEnviar"
              className="form-button"
              disabled={!isProfessionalInformationValid()}
            >
              Enviar
            </button>
          </form>
        )}
        {step === 3 && (
          <form onSubmit={handlePasswordSubmit} className="password-form">
            <h2>Crie sua senha de acesso à conta</h2>
            <div className="password-input-container">
              <input
                type={passwordVisible ? "text" : "password"}
                name="password"
                placeholder="Digite a senha"
                value={password}
                onChange={handlePasswordChange}
                required
                className="password-input"
              />
              <span
                className="password-toggle"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? "🙈" : "👁️"}
              </span>
            </div>
            <div className="password-input-container">
              <input
                type={passwordVisible ? "text" : "password"}
                name="confirmPassword"
                placeholder="Confirme a senha"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
                className="password-input"
              />
            </div>
            <div className="password-requirements">
              <h2>A senha deve ter:</h2>
              <ul>
                <li className={passwordValid.length ? "valid" : ""}>
                  De 8 a 20 dígitos
                </li>
                <li className={passwordValid.uppercase ? "valid" : ""}>
                  Pelo menos 1 letra maiúscula
                </li>
                <li className={passwordValid.lowercase ? "valid" : ""}>
                  Pelo menos 1 letra minúscula
                </li>
                <li className={passwordValid.specialChar ? "valid" : ""}>
                  Pelo menos 1 caractere especial
                </li>
                <li className={passwordValid.number ? "valid" : ""}>
                  Pelo menos 1 número
                </li>
                <li
                  className={
                    passwordValid.noSequentialOrIdentical ? "valid" : ""
                  }
                >
                  Não ter sequenciais ou idênticos como "123 ou aaa"
                </li>
                <li className={passwordValid.match ? "valid" : ""}>
                  Confirmação da senha deve ser igual
                </li>
              </ul>
            </div>
            <button type="button" className="form-button" onClick={onClose}>
              Cancelar
            </button>
            <button
              type="submit"
              className="form-button"
              disabled={!isPasswordValid()}
            >
              Cadastrar
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Form;
