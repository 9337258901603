// src/services/api.js
import axios from 'axios';

const api = axios.create({
  baseURL: 'https://banke2etreinamentos.e2etreinamentos.com.br/api', // Ajuste para a URL e porta do seu backend
  headers: {
    'Authorization': `Basic ${btoa('e2etreinamentos:e2e@123')}`, // Cabeçalho de autenticação padrão
  },
});

export default api;
