// src/pages/Register/Register.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Register.css";

const Register = () => {
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleRegister = () => {
    if (password !== confirmPassword) {
      alert("As senhas não coincidem!");
      return;
    }

    // Chamada à API para registrar o usuário
    fetch("http://127.0.0.1:8082/api/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cpf: cpf,
        email: email,
        password: password,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao registrar o usuário");
        }
        return response.json();
      })
      .then((data) => {
        navigate("/login");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div className="register-container">
      <div className="register-background">
        <img
          src="/imgs/telaregistro.jpg"
          alt="Tela de Registro"
          className="register-background-image"
        />
      </div>
      <div className="register-content">
        <img
          src="/imgs/logo-e2e.svg"
          alt="Logo E2E"
          className="register-logo"
        />
        <form className="register-form">
          <h2>Registro</h2>
          <div className="input-container">
            <input
              type="text"
              name="cpf"
              placeholder="CPF"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <input
              type="password"
              name="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirme a Senha"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="button" className="form-button" onClick={handleRegister}>
            Registrar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
