import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "../../components/Form";
import "./Home.css";

const Home = () => {
  const [cpf, setCpf] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showCtaBox, setShowCtaBox] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleCpfChange = (e) => {
    setCpf(e.target.value);
  };

  const handleVerifyCpf = () => {
    const verificaCpfFuncionarioUrl = `https://banke2etreinamentos.e2etreinamentos.com.br/api/novos/funcionarios/verifica-cpf/${cpf}`;
    const verificaCpfClienteUrl = `https://banke2etreinamentos.e2etreinamentos.com.br/api/cliente/verifica-cpf/${cpf}`;

    fetch(verificaCpfFuncionarioUrl, {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa("e2etreinamentos:e2e@123")}`,
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro na verificação do CPF");
        }
        return response.json();
      })
      .then((isFuncionario) => {
        if (!isFuncionario) {
          throw new Error("O CPF fornecido não é de um funcionário.");
        }
        return fetch(verificaCpfClienteUrl, {
          method: "GET",
          headers: {
            Authorization: `Basic ${btoa("e2etreinamentos:e2e@123")}`,
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro na verificação do CPF");
        }
        return response.json();
      })
      .then((isCliente) => {
        if (isCliente) {
          throw new Error("O CPF fornecido já está registrado como cliente.");
        }
        setShowForm(true);
        setErrorMessage("");
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setShowForm(false);
      });
  };

  const handleOpenCtaBox = () => {
    setShowCtaBox(true);
  };

  const handleCloseCtaBox = () => {
    setShowCtaBox(false);
    setCpf("");
    setErrorMessage("");
  };

  const handleCloseForm = () => {
    setShowForm(false);
    navigate("/", { replace: true });
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div>
      <header className="main-header">
        <div className="header-content">
          <div className="logo-container">
            {/* Adicione o logo aqui, se necessário */}
          </div>
          <nav className="header-nav">
            <button onClick={handleOpenCtaBox} className="open-account-button">
              Abrir conta
            </button>
            <button onClick={handleLoginClick} className="login-button">
              Login
            </button>
          </nav>
        </div>
      </header>
      <main className="main-content">
        <div className="video-container">
          <video
            autoPlay
            muted
            loop
            className="background-video"
            width="100%"
            height="100%"
          >
            <source src="/videos/background.mp4" type="video/mp4" />
            Seu navegador não suporta a tag de vídeo.
          </video>
        </div>
        {!showForm && showCtaBox && (
          <div className="cta-box">
            <button className="close-cta-button" onClick={handleCloseCtaBox}>
              X
            </button>
            <h2>Abra a sua conta</h2>
            <input
              type="text"
              id="cpf-input"
              placeholder="Digite seu CPF"
              value={cpf}
              onChange={handleCpfChange}
            />
            <button onClick={handleVerifyCpf}>Continuar</button>
            {errorMessage && (
              <p id="error-message" style={{ color: "red" }}>
                {errorMessage}
              </p>
            )}
          </div>
        )}
        {showForm && <Form cpf={cpf} onClose={handleCloseForm} />}
      </main>
      <footer>
        <div className="footer-content">
          <img
            src={`${process.env.PUBLIC_URL}/imgs/e2e.svg`}
            alt="Logo E2E"
            className="footer-logo"
          />
          <p>
            <a
              href="https://e2etreinamentos.com.br/politica-de-privacidade"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de Privacidade
            </a>
          </p>
          <p>CNPJ: 18.456.782/0001-33</p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
