// src/services/clientService.js
import api from './api';

export const createClient = async (clientData) => {
  try {
    const response = await api.post('/cliente', clientData);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar cliente', error);
    throw error;
  }
};
