// src/App.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import ClienteLogado from './pages/ClienteLogado/ClienteLogado';

const isAuthenticated = () => {
  const authToken = localStorage.getItem('authToken');
  return !!authToken;
};

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/cliente-logado"
        element={
          isAuthenticated() ? <ClienteLogado /> : <Navigate to="/" replace />
        }
      />
    </Routes>
  );
};

export default App;
