// src/services/accountService.js
import api from './api';

export const createAccount = async (accountData) => {
  try {
    const response = await api.post('/contabanco', accountData);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar conta', error);
    throw error;
  }
};
