// src/services/codeService.js
import api from './api';

export const generateCode = async (cpf) => {
  try {
    const response = await api.post('/password/generate-code', { cpf });
    return response.data;
  } catch (error) {
    console.error('Erro ao gerar código', error);
    throw error;
  }
};
