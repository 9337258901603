import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ClienteLogado.css";

const ClienteLogado = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica se o token de autenticação está presente
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("Token de autenticação não encontrado.");
      navigate("/login", { replace: true }); // Redireciona para a página de login se o token não for encontrado
    }
  }, [navigate]);

  const handleLogout = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("Token de autenticação não encontrado.");
      return;
    }

    fetch("https://banke2etreinamentos.e2etreinamentos.com.br/api/logout", {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Falha ao fazer logout");
        }
        return response.text(); // Ajuste para tratar a resposta como texto
      })
      .then(() => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("nomeCliente");
        navigate("/", { replace: true }); // Redireciona para a página inicial e substitui a entrada atual no histórico
      })
      .catch((error) => {
        console.error("Erro ao fazer logout:", error);
      });
  };

  return (
    <div className="cliente-logado-container">
      <header className="header">
        <div></div>
        <div className="account-menu">
          <span>Olá, {localStorage.getItem("nomeCliente")}</span>
          <div className="dropdown">
            <button className="dropdown-button">Sua Conta</button>
            <div className="dropdown-content">
              <a href="/atualizar-dados">Atualizar dados</a>
              <button className="logout-button" onClick={handleLogout}>Sair</button>
            </div>
          </div>
        </div>
      </header>
      <main className="main-content">
        <h1>Saldo disponível:</h1>
        <p>R$ 3.500,00</p>
      </main>
      <footer className="footer">
        <img
          src={`${process.env.PUBLIC_URL}/imgs/e2e.svg`}
          alt="Logo E2E"
          className="footer-logo"
        />
        <p>© Todos os direitos reservados</p>
      </footer>
    </div>
  );
};

export default ClienteLogado;
