// src/services/passwordService.js
import api from './api';

export const createPassword = async (passwordData) => {
  try {
    const response = await api.post('/password/registerPassword', passwordData);
    return response.data;
  } catch (error) {
    console.error('Erro ao cadastrar senha', error);
    throw error;
  }
};
